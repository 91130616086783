<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick=" $router.push({
            name: 'PaySettings',
            query: { payType: 'Deductions' }
          })"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 220px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          {{ isEditState ? "Edit Deduction" : "New Deduction" }}
        </h1>
      </div>
      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div class="px-3">
      <card class="p-5 mt-6">
        <p class="text-lg font-bold">Deduction Details</p>
        <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
          <div class="col-span-4">
            <div class="text-sm text-jet">Pay Item</div>
            <c-text
              v-if="isEditState"
              :options="payItemList"
              v-model="earningCredential.payItem.name"
              variant="w-full"
              disabled
            />
            <c-select
              v-else
              placeholder="--Select--"
              :options="payItemList"
              v-model="earningCredential.payItemId"
              @onChange="handlePayItem($event)"
              variant="w-full"
              disabled
            />
            <div
              v-if="
                earningCredential.payItemSlug === 'advance' ||
                  earningCredential.payItemSlug === 'loan'
              "
            >
              <div class="text-sm text-gray-500">
                Global Advance and Loan Limit is {{ advanceAndLoanLimit }}% of
                Annual Gross.
              </div>
            </div>
          </div>
          <div class="col-span-4">
            <div class="text-sm text-jet">Name in Payslip</div>
            <c-text
              placeholder="Enter"
              variant="w-full"
              v-model="earningCredential.nameOnPayslip"
              disabled
            />
          </div>
        </div>
      </card>
      <card class="p-5 mt-6" v-show="isVisible">
        <p class="text-lg font-bold">Advance And Loan</p>
        <p class="mt-4 mb-2">
          When is an employee entitled to this advance or loan?
        </p>
        <div class="grid grid-cols-12 gap-7">
          <div class="col-span-12">
            <radio-button
              :options="eligibilityArray"
              col-span="col-span-3"
              class="mx-4 text-darkPurple"
              row-gap="gap-y-1"
              v-model="isAppointment"
            />
          </div>
          <div class="col-span-12">
            <div class="text-base text-jet">
              <checkbox
                :options="taxableArray"
                col-span="col-span-12"
                class="text-darkPurple leading-none px-5"
                label=" No Outstanding Advances & Loans"
              />
            </div>
          </div>
          <div class="col-span-4">
            <div class="text-sm text-jet">
              Total Monthly Limit (Organisation)
            </div>
            <c-text
              placeholder="Enter"
              v-model="earningCredential.advanceMonthlyLimit"
              variant="w-full"
            />
          </div>
          <div class="col-span-4 pt-auto">
            <div class="text-sm text-jet pb-2">Cut-off Date</div>
            <div class="date-flex">
              <datepicker
                placeholder="Choose a date"
                input-class="date-input"
                style="width: 100%; outline: none"
                v-model="earningCredential.advanceCutoffDate"
              />
            </div>
          </div>
          <div class="col-span-3">
            <div class="text-sm text-jet">Max Repayment Duration</div>
            <div class="flex space-x-2">
              <div class="w-6/12">
                <c-text
                  placeholder=""
                  v-model="earningCredential.advanceRepaymentDuration"
                  variant="w-full"
                />
              </div>
              <div class="w-6/12">
                <span class="pt-3 block">Month(s)</span>
                <!--                <c-select-->
                <!--                  placeholder="&#45;&#45;Select&#45;&#45;"-->
                <!--                  :options="payItemList"-->
                <!--                  @onChange="handlePayItemList($event)"-->
                <!--                  variant="w-full"-->
                <!--                />-->
              </div>
            </div>
          </div>
          <div class="col-span-12 mmb-0">
            <div class="text-base text-jet">Is this Interest based?</div>
            <radio-button
              :options="taxableArray"
              col-span="col-span-1"
              class="m-4 mb-0 text-darkPurple"
              v-model="earningCredential.advanceIsInterestBased"
            />
          </div>
          <div
            v-if="
              earningCredential.advanceIsInterestBased === 'true' ||
                earningCredential.advanceIsInterestBased === true
            "
            class="col-span-4 mt-0"
          >
            <div class="text-base text-jet">Interest(%)</div>
            <c-text
              placeholder="Interest value"
              v-model="earningCredential.interest"
            />
          </div>
        </div>
      </card>

      <div class="flex my-10 float-right">
        <div class="flex mb-8">
          <Button
            class="inline-block bg-dynamicBackBtn text-white buttons rounded-md"
            :disabled="creatingEarnings"
            @click="saveEarnings()"
          >
            Save
          </Button>
          <Button
            @click="
              $router.push({
                name: 'PaySettings',
                query: { payType: 'Deductions' }
              })
            "
            class="buttons inline-block"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@/components/Card";
import RadioButton from "@/components/RadioButton";
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button";

export default {
  name: "NewEarnings",
  components: {
    BackButton,
    Breadcrumb,
    Datepicker,
    Card,
    CText,
    CSelect,
    RadioButton,
    Checkbox,
    Button
  },
  data() {
    return {
      isEditState: !!this.$route.params.id,
      openAdvanceAndLoansSidebar: false,
      advanceAndLoanLimit: "",
      isVisible: false,
      isAppointment: false,
      payItemList: [],
      isPayRequest: "false",
      dateVisible: "true",
      // monthVisible: false,
      isShowMonth: false,
      isDisabledRequest: false,
      isDisabled: true,
      creatingEarnings: false,
      earningCredential: {
        orgId: "",
        settingType: "earnings",
        payItemId: "",
        payItemSlug: "",
        payType: "",
        rateTypeSlug: "",
        rateValue: null,
        nameOnPayslip: "",
        includedInGrossPay: false,
        isTaxable: false,
        calculateProrata: false,
        showInPayslip: false,
        payOnRequest: false,
        payMonth: null,
        payrollCutoffDay: null,
        addsToDecember: false,
        has13MonthFixedPay: false,
        month13IsTaxable: false,
        month13RateType: null,
        month13RateValue: null,
        advanceEligibility: "confirmation",
        advanceEligibilityDays: null,
        advanceMonthlyLimit: null,
        advanceCutoffDate: null,
        isPartOfPension: true,
        advanceRepaymentDuration: null,
        advanceIsInterestBased: false,
        interest: 0,
        requiresApproval: false,
        isActive: true,
        includedInTotalEmpCost: true,
        employeeSetsPayStart: false,
        payStartDay: null,
        maximumEmployeeApplicableDays: null,
        requiresNoOutstandingLoans: null,
        levelsSettings: []
      },
      selectedPayItem: null,
      rateType: {},
      ratingType: [],
      activeTab: {},
      breadcrumbs: [
        {
          disabled: false,
          text: "Settings",
          href: "settings",
          id: "Settings"
        },
        {
          disabled: false,
          text: "Pay Settings",
          id: "Pay Settings"
        },
        {
          disabled: false,
          text: this.$route.params.id ? "Edit Deduction" : "New Deduction",
          id: "New Deduction"
        }
      ],
      grossPayArray: [
        { name: "Yes", radioName: "grossPay", value: true },
        { name: "No", radioName: "grossPay", value: false }
      ],
      taxableArray: [
        { name: "Yes", radioName: "taxable", value: true },
        { name: "No", radioName: "taxable", value: false }
      ],
      eligibilityArray: [
        { name: "On Appointment", radioName: "eligibility", value: true },
        { name: "On Confirmation", radioName: "eligibility", value: false }
      ],
      levels: [],
      hasLevels: false,
      approvalArray: [
        { name: "Yes", radioName: "approval", value: true },
        { name: "No", radioName: "approval", value: false }
      ],
      activeArray: [
        { name: "Yes", radioName: "active", value: true },
        { name: "No", radioName: "active", value: false }
      ],
      costArray: [
        { name: "Yes", radioName: "cost", value: true },
        { name: "No", radioName: "cost", value: false }
      ],
      earningArray: [
        { name: "Yes", radioName: "earning", value: true },
        { name: "No", radioName: "earning", value: false }
      ],
      basisArray: [
        { name: "Yes", radioName: "basis", value: true },
        { name: "No", radioName: "basis", value: false }
      ],
      paySlipArray: [
        { name: "Yes", radioName: "paySlip", value: true },
        { name: "No", radioName: "paySlip", value: false }
      ],
      contributionArray: [
        { name: "Yes", radioName: "contribution", value: true },
        { name: "No", radioName: "contribution", value: false }
      ],
      daysArray: [
        { name: "0", id: "0" },
        { name: "1st", id: "1" },
        { name: "2nd", id: "2" },
        { name: "2nd", id: "2" },
        { name: "3rd", id: "3" },
        { name: "4th", id: "4" },
        { name: "5th", id: "5" },
        { name: "6th", id: "6" },
        { name: "7th", id: "7" },
        { name: "8th", id: "8" },
        { name: "9th", id: "9" },
        { name: "10th", id: "10" },
        { name: "11th", id: "11" },
        { name: "12th", id: "12" },
        { name: "13th", id: "13" },
        { name: "14th", id: "14" },
        { name: "15th", id: "15" },
        { name: "16th", id: "16" },
        { name: "17th", id: "17" },
        { name: "18th", id: "18" },
        { name: "19th", id: "19" },
        { name: "20th", id: "20" },
        { name: "20th", id: "20" },
        { name: "21st", id: "21" },
        { name: "23rd", id: "23" },
        { name: "24th", id: "24" },
        { name: "25th", id: "25" },
        { name: "26th", id: "26" },
        { name: "27th", id: "27" },
        { name: "28th", id: "28" }
      ],
      monthsArray: [
        { name: "January", id: "1" },
        { name: " February", id: "2" },
        { name: " March", id: "3" },
        { name: " April", id: "4" },
        { name: " May", id: "5" },
        { name: " June", id: "6" },
        { name: " July", id: "7" },
        { name: " August", id: "8" },
        { name: " September", id: "9" },
        { name: " October", id: "10" },
        { name: " November", id: "11" },
        { name: "   December", id: "12" }
      ],
      requestArray: [
        { name: "On Employee Request", radioName: "request", value: "true" },
        { name: "Company Set Date", radioName: "request", value: "false" }
      ],
      rateTypes: [],
      payItemType: [
        { name: "Regular", id: "regular" },
        { name: "Variable", id: "variable" }
      ]
    };
  },
  methods: {
    getPayItems() {
      this.$_getEarnings().then(result => {
        this.payItemList = result.data.payItems;
        this.ratingType = result.data.rates;
      });
    },
    async getLevels() {
      try {
        const response = await this.$_getLevels();
        const { levels } = response.data;

        // Hide levels card if there are no levels
        if (levels.length === 0) {
          this.hasLevels = false;
          return;
        }

        this.levels = levels.map(item => ({
          levelId: item.id,
          rateValue: null,
          orgId: this.$AuthUser.orgId,
          name: item.name,
          id: item.id,
          isAll: false
        }));

        const allLevels = {
          name: "All Levels",
          id: "0",
          isAll: true,
          rateValue: null
        };
        this.levels.unshift(allLevels);
        this.activeTab = allLevels;

        this.hasLevels = true;
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async handleLevel(tab) {
      try {
        this.activeTab = tab;

        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async updateLevelsValue(value) {
      try {
        this.levels = this.levels.map(item => ({
          ...item,
          rateValue: value
        }));

        this.activeTab.rateValue = value;

        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    // eslint-disable-next-line consistent-return
    handlePayItem(value) {
      const change = this.payItemList.find(item => item.id === value);
      this.earningCredential.nameOnPayslip = change.name;
      this.earningCredential.payItemSlug = change.slug;

      // Assign Rate types value to the selected item Rate types
      this.rateTypes = change.rateTypes.map(item => ({
        name: item.label,
        id: item.slug
      }));

      // Enable request
      this.isDisabledRequest = false;
      // Handle case where Advance is selected
      if (change.slug === "advance" || change.slug === "loan") {
        return this.handleAdvanceAndLoan();
      }
      if (change.slug === "month13") {
        return this.handle13thMonth();
      }
      if (change.slug === "basic") {
        return this.handleBasicSalary();
      }
      if (change.slug === "overtimeAllowance") {
        return this.handleovertimeAllowance();
      }
      if (change.slug === "leaveAllowance") {
        return this.handleleaveAllowance();
      }
      this.payItemType = [
        { name: "Regular", id: "regular" },
        { name: "Variable", id: "variable" }
      ];
      this.earningCredential.includedInGrossPay = false;
      this.isPayRequest = false;
      this.isDisabled = false;
      this.isVisible = false;
      this.dateVisible = "true";
    },

    handleRateType(value) {
      const rateType = this.rateTypes.find(element => value === element.id);
      if (rateType) {
        this.rateType = rateType;
      }
      return this.rateType;
    },
    // Set variable when Advance OR Loan is selected
    handleAdvanceAndLoan() {
      this.payItemType = [{ name: "Variable", id: "variable" }];
      this.earningCredential.includedInGrossPay = false;
      this.isDisabled = true;
      this.isPayRequest = "true";
      this.isDisabledRequest = true;
      this.isVisible = true;
    },
    setAdvanceAndLoanLimit(value) {
      this.advanceAndLoanLimit = value;
      this.openAdvanceAndLoansSidebar = false;
    },
    async getAdvanceAndLoanLimit() {
      const response = await this.$_getGeneralAdvanceSettings();
      this.advanceAndLoanLimit = response.data.setting.maxLoanAndAdvanceRequest;
    },
    // Set Regular and Gross pay yes when Basic Salaryis selected
    handle13thMonth() {
      this.payItemType = [{ name: "Variable", id: "variable" }];
      this.earningCredential.includedInGrossPay = true;
      this.isPayRequest = "false";
      this.isDisabled = false;
      this.isDisabledRequest = true;
      this.isVisible = false;
    },
    // Set Regular and Gross pay yes when Basic Salaryis selected
    handleBasicSalary() {
      this.payItemType = [{ name: "Regular", id: "regular" }];
      this.earningCredential.includedInGrossPay = true;
      this.isPayRequest = false;
      this.isDisabled = true;
      this.isVisible = false;
    },

    handleovertimeAllowance() {
      this.payItemType = [{ name: "Variable", id: "variable" }];
      this.earningCredential.includedInGrossPay = false;
      this.isPayRequest = true;
      this.isDisabled = true;
      this.isDisabledRequest = true;
      this.isVisible = false;
    },

    handleleaveAllowance() {
      this.payItemType = [{ name: "Variable", id: "variable" }];
      this.earningCredential.includedInGrossPay = false;
      this.isPayRequest = true;
      this.isDisabled = true;
      this.isDisabledRequest = true;
      this.isVisible = false;
    },
    formatDate(date) {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();

      if (month.length < 2) month = `0${month}`;
      if (day.length < 2) day = `0${day}`;

      return [year, month, day].join("-");
    },
    async saveEarnings() {
      try {
        this.creatingEarnings = true;
        this.earningCredential.orgId = this.$AuthUser.orgId;
        this.earningCredential.levelsSettings = this.levels.filter(
          level =>
            level.isAll === false &&
            level.rateValue !== this.earningCredential.rateValue
        );
        this.earningCredential.settingType = "earnings";
        if (this.earningCredential.advanceCutoffDate) {
          this.earningCredential.advanceCutoffDate = new Date(
            this.earningCredential.advanceCutoffDate
          ).getDate();
        }

        if (this.isEditState === true) {
          await this.$_updatePayItemSettings(this.earningCredential);
        } else {
          await this.$_payItemSetting(this.earningCredential);
        }

        this.$router.push({
          name: "PaySettings",
          query: { payType: "Deductions" }
        });

        this.creatingEarnings = false;
      } catch (error) {
        this.creatingEarnings = false;
        throw new Error(error);
      }
    },
    changePayItem(value) {
      this.categories.map(element => {
        if (element.id === value) {
          this.payItemList = element.payItems;
        }
        return value;
      });
    },
    handlePayItemList(event) {
      this.earningCredential.advanceEligibilityDays = event.target.value;
    },
    setRateType(rateType) {
      this.earningCredential.rateTypeSlug = rateType;
    },
    addAllLevelsTab() {
      const allLevels = {
        name: "All Levels",
        id: "0",
        isAll: true,
        rateValue: this.earningCredential.rateValue
      };
      this.levels.unshift(allLevels);
      this.activeTab = allLevels;
    }
  },
  computed: {
    disabledRateType() {
      if (this.earningCredential.includedInGrossPay.toString() === "true") {
        this.setRateType("percentGrossEarn");
        return true;
      }
      return false;
    }
  },
  watch: {
    isAppointment(value) {
      if (value) {
        this.earningCredential.advanceEligibility = "appointment";
      } else {
        this.earningCredential.advanceEligibility = "confirmation";
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.isEditState = true;
    }
  },
  async mounted() {
    await this.getPayItems();
    await this.getLevels();
    await this.getAdvanceAndLoanLimit();
    if (this.$route.params.id) {
      this.loading = true;
      this.$_getOnePayItemSettings(this.$route.params.id).then(async result => {
        this.earningCredential = result.data.paySetting;

        this.handlePayItem(this.earningCredential.payItemId);
        await this.handleRateType(this.earningCredential.rateTypeSlug);
        this.ratesValue = this.earningCredential.rateValue;

        this.isPayRequest = this.earningCredential.payOnRequest.toString();

        // populate levels
        this.levelSettings = result.data.levels;
        this.levels = this.levelSettings;
        this.addAllLevelsTab();

        this.loading = false;
      });
    }
  }
};
</script>
